import React from 'react'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import { isNewPDP } from 'src/utils/isNewPDP'

import SameDayLabel from './SameDayLabel'
import { ShippingInfoBeforeDate } from './ShippingInfoBeforeDate'
import ShippingPrice from './ShippingPrice'
import DockOrder from './dockOrder'

type ShippingSimulationContainerProps = {
  slas: ShippingSimulation[]
  today: boolean
  pickupPointsApi: PickupPointsProps[] | undefined
  setIsToday: React.Dispatch<React.SetStateAction<boolean>>
}

const ShippingSimulationContainer = ({
  slas,
  today,
  pickupPointsApi,
  setIsToday,
}: ShippingSimulationContainerProps) => {
  const { person } = useSession()
  const { sendProductAbTestEvent } = useProductAbTestEvent()

  const sendDontKnowPostalSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click',
      component: 'botao_sublinhado',
      position_interaction: 'direita',
      element_name: 'nao_sei_meu_cep',
      section: 'info_gerais',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  const handleSlasOrdenationByShippingEstimate = (
    shippingSlas: ShippingSimulation[]
  ) => {
    const priority = {
      Premium: 1,
      Retira: 2,
      Normal: 3,
    }

    return shippingSlas.sort((a, b) => {
      const slaA = a?.name.toLowerCase()
      const slaB = b?.name.toLowerCase()

      const priorityA = slaA.includes('retira')
        ? 'Retira'
        : slaA === 'premium'
        ? 'Premium'
        : 'Normal'

      const priorityB = slaB.includes('retira')
        ? 'Retira'
        : slaB === 'premium'
        ? 'Premium'
        : 'Normal'

      return priority[priorityA] - priority[priorityB]
    })
  }

  const ordenatedSlas = handleSlasOrdenationByShippingEstimate(slas)

  return (
    <div className="shipping-simulator__response-container">
      {today && <SameDayLabel />}
      <ShippingInfoBeforeDate slas={slas} holidayDate="2023-12-24" />
      <>
        {ordenatedSlas.length ? (
          <>
            {ordenatedSlas.map(
              (sla: ISLAMinimumTypeTimeLeftForDeliveryToday, key) => {
                return (
                  <div
                    className={`shipping-simulator__info-outContainer ${
                      sla?.id === 'Premium' && 'premium'
                    }`}
                    key={`${sla.id}-container`}
                  >
                    <ShippingPrice
                      key={sla.id}
                      keyProp={key}
                      sla={sla}
                      pickupPoints={pickupPointsApi}
                      setIsToday={setIsToday}
                      today={today}
                    />
                    {sla?.deliveryIds?.[0]?.dockId === 'ENCOMENDA' && (
                      <DockOrder />
                    )}
                  </div>
                )
              }
            )}
          </>
        ) : (
          <a
            target="_blank"
            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
            className="shipping-simulator__link"
            rel="noopener noreferrer"
            onClick={sendDontKnowPostalSelectItemAbTestEventToAnalytics}
          >
            Não sei meu cep
          </a>
        )}
      </>
    </div>
  )
}

export default ShippingSimulationContainer
